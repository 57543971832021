<template>
    <main class="table-details-container">
        <div class="breadcrumb-wrapper">
            <div class="breadcrumb-routers">
                <a href="/configuracoes-da-clinica/tabelas">Tabelas</a>
                <ChevronRight class="crevron-right" />
                <span>Itens da tabela</span>
            </div>
            <div class="actions" v-if="editable">
                <div class="toggle-select-wrapper">
                    <toggle-button
                        :width="35"
                        v-model="multiPages"
                        :disabled="loading"
                        :sync="true"
                        :height="22"
                        :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
                    />
                    <span>Selecionar de todas as páginas</span>
                </div>
                <Upload 
                    class="import-icon"  
                    v-b-tooltip.hover title="Importar itens"
                    @click="$bvModal.show('import-items-modal')"
                />
            </div>
            
        </div>

        <LayoutItemHealthPlanProps 
            v-if="table?.type === CONSTANTS.TYPE_PROCEDURE"
            :multiPages="multiPages"
            :editable="editable"
            :table="table" 
        />
        <LayoutItemOtherExpenseProps 
            v-else-if="table?.type === CONSTANTS.TYPE_OTHER_EXPENSES"
            :multiPages="multiPages"
            :editable="editable"
            :table="table" 
        />
        <LayoutMatMedBrasindice 
            v-else-if="table?.type === CONSTANTS.TYPE_MAT_MED_BRASINDICE"
            :multiPages="multiPages"
            :table="table" 
        />
        <LayoutMatMedSimpro 
            v-else-if="table?.type === CONSTANTS.TYPE_MAT_MED_SIMPRO"
            :multiPages="multiPages"
            :table="table" 
        />
        <div v-else>Tipo de tabela <b>{{ table?.type }}</b> não implementada</div>

        <ImportItemsModal 
            v-if="table" 
            :table="table"
        />
        
    </main>
</template>
<script>
import api from '@tables/api'
import * as CONSTANTS from '@tables/utils/constants';

export default {
    components: {
        Upload: () => import('@/assets/icons/upload-excel.svg'),
        ChevronRight: () => import('@/assets/icons/chevron-right.svg'),   
        ImportItemsModal: () => import('@items/modals/ImportItemsModal.vue'), 
        LayoutItemHealthPlanProps: () => import('@itemHealthPlanProps/layouts/LayoutItemHealthPlanProps'),  
        LayoutItemOtherExpenseProps: () => import('@itemOtherExpenseProps/layouts/LayoutItemOtherExpenseProps'),
        LayoutMatMedBrasindice: () => import('@matMed/layouts/LayoutMatMedBrasindice'),
        LayoutMatMedSimpro: () => import('@matMed/layouts/LayoutMatMedSimpro'),
    },
    created() {
        this.getTable()
    },
    computed: {
        editable() {
            return !!this.table?.clinic_id || 
                ![CONSTANTS.TYPE_PROCEDURE, CONSTANTS.TYPE_OTHER_EXPENSES]
                    .includes(this.table?.type)
        }
    },
    data() {
        return {
            CONSTANTS,
            multiPages: false,
            loading: false,
            tableId: this.$route.params.id,
            table: null,
        }
    },
    methods: {
        getTable() {
            api.getTable(this.tableId)
                .then(({ data }) => (this.table = data))
                .catch(error => this.$toast.error(error.message))
        }
    }
}
</script>
<style lang="scss" scoped>
.actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    .import-icon {
        width: 24px;
        fill: var(--type-active);
        cursor: pointer;
    }
}
.toggle-select-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}
.table-details-container {
    padding: 1rem;
}
.breadcrumb-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 1rem 0;
}
.breadcrumb-routers {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
    color: var(--blue-500);
    font-weight: 600;
    span {
        color: var(--type-placeholder);
    }
}
.table-name {
    display: flex;
    flex-direction: column;
}
.table-type {
    display: flex;
    padding: 0.2rem 0.5rem;
    border-radius: 30px;
    background-color: var(--neutral-200);
    color: var(--type-active);
    font-size: 10px;
}
.crevron-right {
    width: 18px;
    stroke: var(--neutral-600);
}
</style>